<template>
    <Layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader title="Pending Payments" />
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="mb-3">
                    <div class="row gx-2">
                        <!-- <div class="col"><h3 class="my-1 font-size-18 text-dark-cstm">The Wreckage of Attraction</h3></div> -->
                        <div class="col text-end">
                            <div class="topBarCstmTable searchBarStyle position-relative">
                                <input type="text" v-model="searchValueTable" class="form-control"
                                    placeholder="Search..." />
                                <span class="iconSearch bx bx-search-alt"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <EasyDataTable :headers="headers" :items="tableItems" :search-value="searchValueTable" border-cell
                    theme-color="#3aafa9" :rows-per-page="10" buttons-pagination table-class-name="table-custom-style"
                    show-index>
                    <template #item-title="{ email, title, packages }">
                        <template v-if="email == null">{{ title }}</template>
                        <template v-else>
                            <div class="mainTrRow">
                                <div v-for="packageData in packages" :key="packageData.id" class="columnTdInr">
                                    <span v-if="packages.length == 1">{{ packageData.title }} | <sup
                                            style="top:0;">({{ packageData.type }} Package)</sup> </span>
                                    <span v-else>{{ packageData.title }} | <sup style="top:0;">({{ packageData.type }}
                                            Package)</sup> </span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template #item-amount="{ amount, email, packages, extraService, wordCount, story_id }">
                        <template v-if="email == null">
                            <p
                                v-if="extraService && extraService.title !== '5 Journals' && extraService.title !== '15 Journals'">
                                ${{ (((parseInt(wordCount)) / 1000 *
                                    parseFloat(extraService.amount)).toFixed(2)) }}</p>
                            <p v-else>${{ parseFloat(amount).toFixed(2) }}</p>
                        </template>
                        <template v-else>
                            <template v-for="packageData in packages" :key="packageData.id">
                                <!-- <span v-if="packages.length == 1">{{ packageData.amount }}</span> -->
                                <!-- <span>{{ packageData.amount }}</span> -->
                            </template>
                            ${{ sumAmount(packages, story_id) }}
                        </template>
                    </template>
                    <template #item-story_id="{ story_id }">
                        <span>{{ story_id.storyTitle }}</span>
                    </template>
                    <template #item-status="{ status }">
                        <span>{{ status }}</span>
                    </template>
                    <template #item-action="{ email, status, paymentLink, token }">
                        <template v-if="email == null">
                            <router-link :to="`/payment/${token}`" class="btn btn-theme"
                                :disabled="status == 'Success'">Buy Package</router-link>
                        </template>
                        <template v-else>
                            <router-link :to="`/payment/${paymentLink}`" class="btn btn-theme"
                                :disabled="status == 'Success'">Buy Package</router-link>
                        </template>
                    </template>
                </EasyDataTable>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../router/layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import axios from "axios";
// import CryptoJS from 'crypto-js';
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
export default {
    page: {
        title: "Add Extra Services",
        meta: [
            {
                name: "description",
                content: "description",
            },
        ],
    },
    data() {
        return {
            title: "Add Extra Services",
            url: process.env.VUE_APP_URL,
            submitted: false,
            show: [],
            tableItems: [],
            headers: [
                { text: "Service", value: "title" },
                { text: "Amount", value: "amount" },
                { text: "Story Title", value: "story_id" },
                { text: "Payment Status", value: "status" },
                { text: "Payment Link", value: "action" },
            ],

        };

    },
    components: {
        PageHeader,
        Layout,
        EasyDataTable,
    },
    mounted() {
        this.getPendingPayment()
    },
    activated() {
        this.getPendingPayment();
    },

    methods: {
        sumAmount(packages, story) {
            let totalAmount = 0;
            let submission = null;
            let fiction = null;
            let editing = null;
            for (const packageData of packages) {
                if (packageData.type == 'submission') {
                    submission = packageData;
                } else if (packageData.type == 'flash') {
                    fiction = packageData;
                } else if (packageData.type == 'editing') {
                    editing = packageData;
                }
            }

            if (submission) {
                totalAmount += parseFloat(submission.amount);
            }

            if (fiction) {
                totalAmount += parseFloat(fiction.amount);
            }

            if (editing && editing.title == 'Consulting: 1 hour') {
                totalAmount += parseFloat(editing.amount);
            }

            if ((submission?.title == '20 Journals' || fiction?.title == '20 Journals (3 Stories)' || submission?.title == 'Journals List Only') && (editing && editing.title !== 'Consulting: 1 hour')) {
                totalAmount -= (parseFloat(((Number(story.wordCount) / 1000) * editing.amount)) * 10) / 100;
            } else if ((submission?.title == '20 Journals' || fiction?.title == '20 Journals (3 Stories)' || submission?.title == 'Journals List Only') && (editing && editing.title == 'Consulting: 1 hour')) {
                totalAmount -= (parseFloat(editing.amount) * 10) / 100;
            }

            if (story.wordCount > 3500 && !editing) {
                totalAmount += ((Number(story.wordCount) - 3500) / 1000) * 35;
            } else if (editing?.title == 'Line Editing' && submission?.title == 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 85;
            } else if (editing?.title == 'Line Editing' && submission?.title != 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 85;
            } else if (editing?.title == 'Copy Editing' && submission?.title != 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 60;
            } else if (editing?.title == 'Copy Editing' && submission?.title == 'Journals List Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 60;
            } else if (editing?.title == 'Read Only') {
                totalAmount += (Number(story.wordCount) / 1000) * 35;
            }
            return totalAmount.toFixed(2);

        },
        // sumAmount(packages) {
        //     let totalAmount = 0;
        //     for (const packageData of packages) {
        //         totalAmount += parseInt(packageData.amount);
        //     }
        //     return totalAmount;
        // },

        getPendingPayment() {
            axios
                .post(this.url + "api/getPendingPayment", {
                    id: atob(
                        decodeURIComponent(
                            JSON.parse(localStorage.getItem("authenticatedUserId"))
                        )
                    ),
                })
                .then((res) => {
                    this.tableItems = res.data;
                });
        },
    },
};
</script>
<style>
.columnTdInr {
    padding: 2px 8px;
    border-bottom: 1px solid #e5e5e5;
    background: rgb(43 122 120 / 15%);
    margin: 2px 0;
    text-align: center;
    font-weight: 500;
}
</style>